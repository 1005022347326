@bg: #282c34;

.App-logo {
  height: 70vmin;
  pointer-events: none;
}

.App-header-logo {
  display: flex;
  justify-content: center;
  //margin-top: 50%; // TODO @media
  margin-top: calc(~'50% - 56px');
  margin-bottom: 50%;
  z-index: 1;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 7s linear;
  }
}

.App-header {
  background-image: url('./assets/background_top.png'), url('./assets/background.png');
	background-repeat: no-repeat, repeat-y;
	background-position: center top, center top;
	background-size: 462px 35px, 462px;
  background-color: #282c34;
  min-height: calc(~'100vh - 56px');
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header-linear-gradient {
	width: 462px;
  background-image: linear-gradient(to right, @bg, transparent), linear-gradient(to left, @bg, transparent) !important;
	background-repeat: repeat-y, repeat-y;
	background-position: left top, right top;
	background-size: 15px 100%, 15px 100%;
  min-height: calc(~'100vh - 56px');
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@base-color: #754821;