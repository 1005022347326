.App-header-svg {
  position: absolute;
  width: 462px;
  height: calc(~'100% - 56px');
	z-index: 2 !important;
  background-image: url('./assets/title-462.png');
  background-repeat: no-repeat;
  background-position: center center;
}

@base-color: #754821;