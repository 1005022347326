.inline {
	display:inline;
}

.chart {
  width: 150px;
  height: 145px;
}

audio {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
}

audio {
	width: 45px;
}

@base-color: #754821;