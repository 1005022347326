@bg: #282c34;
@bg-content: white;
@bg-content-dark: @bg;

.Main-content-left {
  text-align: left;
}

.Main-background {
	width: 100%;
	background-color: @bg;
}

.Main-content {
  color: @bg; // Color text black light mode
	width: 95%;
	min-height: calc(~'100vh - 30px');;
	padding-left: 5%;
	padding-right: 5%;
	padding-top: 15px;
	padding-bottom: 15px;
	background-color: @bg-content !important;
	margin: auto;
	border-style: solid;
	border-radius: 15px;
  box-shadow: 0 4px 8px @bg; 
}

.Main-content-dark {
	width: 95%;
	min-height: calc(~'100vh - 30px');;
	padding-left: 5%;
	padding-right: 5%;
	padding-top: 15px;
	padding-bottom: 15px;
	background-color: @bg-content-dark !important;
	margin: auto;
	border-style: solid;
	border-radius: 15px;
  box-shadow: 0 4px 8px @bg; 
}

@media screen and (min-width: 462px) {
  .Main-background-zone {
    width: 462px;
    background-image: url('./assets/texture_noyer_big_bg.png');
    background-repeat: repeat;
    background-position: center top;
		min-height: 100vh;
		margin: auto;
  }

	.Main-background-linear-gradient {
		width: 462px;
		background-image: linear-gradient(to right, @bg, transparent), linear-gradient(to left, @bg, transparent), linear-gradient(to top, transparent, @bg), linear-gradient(to bottom, transparent, @bg) !important;
		background-repeat: repeat-y, repeat-y, repeat-x, repeat-x;
		background-position: left top, right top, center top, center bottom;
		background-size: 15px 100%, 15px 100%, 100% 15px, 100% 15px;
		min-height: 100vh;
		margin: auto;
		padding-top: 15px;
		padding-bottom: 15px;
  }
}

@media screen and (min-width: 600px) {
  .Main-background-zone {
    width: 600px;
    background-image: url('./assets/texture_noyer_big_bg.png');
    background-repeat: repeat;
    background-position: center top;
		min-height: 100vh;
		margin: auto;
  }

	.Main-background-linear-gradient {
		width: 600px;
		background-image: linear-gradient(to right, @bg, transparent), linear-gradient(to left, @bg, transparent), linear-gradient(to top, transparent, @bg), linear-gradient(to bottom, transparent, @bg) !important;
		background-repeat: repeat-y, repeat-y, repeat-x, repeat-x;
		background-position: left top, right top, center top, center bottom;
		background-size: 15px 100%, 15px 100%, 100% 15px, 100% 15px;
		min-height: 100vh;
		margin: auto;
		padding-top: 15px;
		padding-bottom: 15px;;
  }
}

@media screen and (min-width: 768px) {
  .Main-background-zone {
    width: 768px;
    background-image: url('./assets/texture_noyer_big_bg.png');
    background-repeat: repeat;
    background-position: center top;
		min-height: 100vh;
		margin: auto;
  }

	.Main-background-linear-gradient {
		width: 768px;
		background-image: linear-gradient(to right, @bg, transparent), linear-gradient(to left, @bg, transparent), linear-gradient(to top, transparent, @bg), linear-gradient(to bottom, transparent, @bg) !important;
		background-repeat: repeat-y, repeat-y, repeat-x, repeat-x;
		background-position: left top, right top, center top, center bottom;
		background-size: 15px 100%, 15px 100%, 100% 15px, 100% 15px;
		min-height: 100vh;
		margin: auto;
		padding-top: 15px;
		padding-bottom: 15px;;
  }
}

@media screen and (min-width: 1024px) {
  .Main-background-zone {
    width: 1024px;
    background-image: url('./assets/texture_noyer_big_bg.png');
    background-repeat: repeat;
    background-position: center top;
		min-height: 100vh;
		margin: auto;
  }

	.Main-background-linear-gradient {
    width: 1024px;
		background-image: linear-gradient(to right, @bg, transparent), linear-gradient(to left, @bg, transparent), linear-gradient(to top, transparent, @bg), linear-gradient(to bottom, transparent, @bg) !important;
		background-repeat: repeat-y, repeat-y, repeat-x, repeat-x;
		background-position: left top, right top, center top, center bottom;
		background-size: 15px 100%, 15px 100%, 100% 15px, 100% 15px;
		min-height: 100vh;
		margin: auto;
		padding-top: 15px;
		padding-bottom: 15px;;
  }
}

@media screen and (min-width: 1200px) {
  .Main-background-zone {
    width: 1200px;
    background-image: url('./assets/texture_noyer_big_bg.png');
    background-repeat: repeat;
    background-position: center top;
		min-height: 100vh;
		margin: auto;
  }

	.Main-background-linear-gradient {
    width: 1200px;
		background-image: linear-gradient(to right, @bg, transparent), linear-gradient(to left, @bg, transparent), linear-gradient(to top, transparent, @bg), linear-gradient(to bottom, transparent, @bg) !important;
		background-repeat: repeat-y, repeat-y, repeat-x, repeat-x;
		background-position: left top, right top, center top, center bottom;
		background-size: 15px 100%, 15px 100%, 100% 15px, 100% 15px;
		min-height: 100vh;
		margin: auto;
		padding-top: 15px;
		padding-bottom: 15px;;
  }
}

.radio-group-label {
  padding: 8px 2px 8px 10px;
  display: 'inline-block';
  vertical-align: 'middle';
}

@base-color: #754821;