@import 'rsuite/lib/styles/index.less';
@bg: #282c34;

.App-navbar {
	position: absolute top;
	width: 100%;
	background-image: linear-gradient(to bottom, transparent, @bg), linear-gradient(to top, transparent, lighten(@base-color, 15%)), url('./assets/texture_noyer_56pxh.png');
	background-repeat: repeat-x, repeat-x, repeat;
	background-position: center bottom, center top, center top;
	background-size: 100% 15%, 100% 15%, 100% 100%;
}

// TODO
.App-navbar-content > a:focus {
  background-color: transparent	!important;
}

.App-navbar-content > a:hover {
  background-color: transparent	!important;
	text-decoration: underline !important;
}

// unused
@media screen and (min-width: 500px) {
  .App-navbar_ {
    position: absolute top;
    width: 100%;
    background-image: url('./assets/texture_noyer_left.png');
    background-repeat: repeat-y;
    background-position: left top;
  }
}

// unused
@media screen and (min-width: 1000px) {
  .App-navbar_ {
    position: absolute top;
    width: 100%;
    background-image: url('./assets/texture_noyer_left.png'), url('./assets/texture_noyer_right.png');
    background-repeat: repeat-y, repeat-y;
    background-position: left top, right top;
  }
}

// unused
@media screen and (min-width: 1200px) {
  .App-navbar_ {
    position: absolute top;
    width: 100%;
    background-image: url('./assets/texture_noyer_left.png'), url('./assets/texture_noyer_right.png');
    background-repeat: repeat-y, repeat-y;
    background-position: left top, right top;
  }
}

@base-color: #754821;