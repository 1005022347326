.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.App-footer {
	width: 100%;
	background-color: red;;
	min-height: 10vh;
	margin: auto;
}

@base-color: #754821;